/* eslint-disable react/prop-types */
import React from 'react'
const TotalSales = ({licensesPrices,licensesExpiredPrices}) => {
    return (
        <div className="flex flex-col items-center">
            <div className="mb-2">
                <p className="text-lg font-semibold text-gray-900 items-center my-2">Total Sales</p>
            </div>
            <div className="flex flex-col">
                <div className="flex md:flex-row space-x-8">
                    <div className="shadow-lg rounded-md p-4 bg-blue">
                        <div className="">
                            <div className="flex flex-col">
                                <div className="flex justify-between space-x-8 my-2 w-56">
                                    <div className="">
                                        <div className="uppercase text-sm text-gray-200 font-bold">
                                        Total Prices Available licenses
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex space-x-2 items-center">
                                                <div className="text-2xl text-white font-bold">
                                                $ {licensesPrices}
                                                </div>
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <svg className="h-16 w-20 text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>                                
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex justify-between space-x-8 w-56 my-2">
                                    <div className="">
                                        <div className="uppercase text-sm text-gray-200 font-bold">
                                        Total Prices of expired licenses
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex space-x-2 items-center">
                                                <div className="text-2xl text-white font-bold">
                                                $ {licensesExpiredPrices}
                                                </div>
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <svg className="h-16 w-20 text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>                                
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="flex justify-between space-x-8 w-56 my-2">
                                    <div className="">
                                        <div className="uppercase text-sm text-gray-200 font-bold">
                                        Total
                                        </div>
                                        <div className="mt-1">
                                            <div className="flex space-x-2 items-center">
                                                <div className="text-2xl text-white font-bold">
                                               $ {parseFloat(licensesPrices) + parseFloat(licensesExpiredPrices)}
                                                </div>
                                                   
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <svg className="h-16 w-20 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TotalSales