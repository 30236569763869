/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import CloseIcon from '@mui/icons-material/Close'
import { StoreContext } from '../context/context'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Box from '@mui/material/Box'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
const EditModal = ({showModal, setShowModal,currentLicense}) => {
    const {users,accessTokenFauna, getLicenses} = useContext(StoreContext)
    const [userEmail, setUserEmail] = useState()
    const [updateMessage, setUpdateMessage] = useState()
    const closeModal = () => {
        setShowModal(false)
    }
    const validationSchema = Yup.object().shape({
        available: Yup.string()
            .required('Available is required'),
        used: Yup.string()
            .required('Used is required'),
        expire: Yup.string()
            .required('Expire date is required'),
        block: Yup.bool().required('Required block true/false value.')
 
    })
 
    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width                 : 'auto',
            height                : 'auto',
            padding               : '0px',
            background            : '#efeff0',
           
        },
        overlay: {
            zIndex: 1000,
            background: '#0000009a',
        }
    }
    useEffect(()=>{
        const userEmail = users.filter(user=> user.user_id === currentLicense.data.userID)
        if(userEmail.length>0)
            setUserEmail(userEmail[0].email)
    },[])
    return (
        <div>
            <Modal 
                isOpen={showModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="p-4">
                    <div style={{textAlign:'right'}}>
                        <button onClick={() => {closeModal()}}><CloseIcon/> </button>
                    </div>
                    <section className="max-w-4xl p-6 mx-auto dark:bg-gray-800">
                        <h1 className="text-xl font-bold text-blue dark:text-black my-6">Update License {currentLicense.data.product} of user {userEmail}</h1>
                        <Formik
                            initialValues={{
                                available: currentLicense.data.available,
                                used: currentLicense.data.used,
                                expire: currentLicense.data.expire,
                                block: currentLicense.data.block
                                
                            }}
                            validationSchema= {validationSchema}
                            onSubmit={async (values) => {
                                currentLicense.data['available']= values.available
                                currentLicense.data['used']= values.used
                                currentLicense.data['block']= values.block
                                currentLicense.data['expire']= new Date(values.expire).getTime()
                                console.log('current',currentLicense)
                                fetch(`${process.env.REACT_APP_UPDATELICENSES}`, {
                                    method:'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    },
                                    body: JSON.stringify({license:[currentLicense], accessToken:accessTokenFauna})
                                })
                                    .then(res=>res.json())
                                    .then(res=>{
                                        console.log('token data res',res)
                                        if(res === 'true') {
                                            setUpdateMessage('License is updated.')
                                            getLicenses(accessTokenFauna)
                                        }
                                       
                                    })
                                    .catch(err=>{
                                        console.log('err profile',err)
                                    })
                      
                            }}
                        >
                            {({ isValid,dirty, setFieldValue, values }) => ( 
                                <Form>
                                    <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                                        <div>
                                            <label className="text-black dark:text-gray-200" htmlFor="username">ID</label>
                                            <input disabled id="username" type="text"  value={currentLicense.data.id} className="disabled:text-yellow block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
                                        </div>

                                        <div>
                                            <label className="text-black dark:text-gray-200" htmlFor="emailAddress">Product Name</label>
                                            <input disabled id="emailAddress" type="text" value={currentLicense.data.product} className="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
                                        </div>

                                        <div>
                                            <label className="text-black dark:text-gray-200" htmlFor="password">User ID</label>
                                            <input disabled  type="text" value={currentLicense.data.userID} className="block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
                                        </div>

                                        <div>
                                            <label className="text-black dark:text-gray-200" >Expiry Date</label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    label="Expire Date"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={values.expire}
                                                    onChange={(e)=>setFieldValue('expire',e)}
                                                    renderInput={({ inputRef, inputProps, InputProps }) => <Box className="bg-white block w-full px-4 py-2 mt-2 text-gray-700 border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <input ref={inputRef} {...inputProps} />
                                                        {InputProps?.endAdornment}
                                                    </Box>}
                                                />
                                            </LocalizationProvider>
                                            <div className="text-red-500 text-xs">
                                                <ErrorMessage name="expire" />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="text-black dark:text-gray-200" htmlFor="passwordConfirmation">Available</label>
                                            <Field
                                                id="available"
                                                name="available" 
                                                type="number"
                                                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
                                            <div className="text-red-500 text-xs">
                                                <ErrorMessage name="available" />
                                            </div>
                                        </div>
                                       
                                        <div>
                                            <label className="text-black dark:text-gray-200" htmlFor="passwordConfirmation">Used</label>
                                            <Field
                                                id="used"
                                                name="used" 
                                                type="number"
                                                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"/>
                                            <div className="text-red-500 text-xs">
                                                <ErrorMessage name="used" />
                                            </div>
                                        </div>
                                        <div>
                                            <label className="text-black dark:text-gray-200">Block License</label>
                                            <Field
                                                id="block"
                                                name="block" 
                                                type="checkbox"
                                                className="ml-2"/>
                                            <div className="text-red-500 text-xs">
                                                <ErrorMessage name="block" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-end mt-6">
                                        <button disabled={!isValid && !dirty  } className={`${isValid && dirty ? 'bg-mainColor' : 'bg-gray-200'} px-6 py-2 leading-5 transition-colors duration-200 transform bg-blue rounded-md text-white focus:outline-none focus:bg-gray-600`}>Save</button>
                                    </div>
                                    {updateMessage}
                                </Form>
                            )}
                        </Formik>
                    </section>              
                </div>
                    
            </Modal>
        </div>
    
    )
}
export default EditModal