/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react'
import { StoreContext } from '../context/context'
const HeaderTableUsers = ({setSlicedData}) => {
    const [ searchWord, setSearchWord] = useState('all-users')
    // eslint-disable-next-line no-unused-vars
    const [ searchingWord, setSearchingWord] = useState('all-users')
    let {users, licenses} = useContext(StoreContext)
    const setsearchingword = (e) => {
        setSearchingWord(e.target.value)
    }
    const search = () => {
        console.log('search word',searchWord)
        if(searchWord === 'last-10-logins') {
            setSlicedData(users.sort((a,b)=>{
                return new Date(b.last_login)-new Date(a.last_login)
            }).slice(0,10))
        }
        if(searchWord === 'new-users-in-current-month') {
            const currentMonth = new Date ().getMonth()+1
            const currentYear = new Date ().getFullYear()
            setSlicedData(users.filter(user=>{
                const userMonth = new Date (user.created_at).getMonth() +1
                const userYear = new Date(user.created_at).getFullYear()
                return (currentMonth=== userMonth) && (currentYear === userYear)
            }))
        }
        if(searchWord === 'users-without-licenses') {
            setSlicedData(users.filter(user => !licenses.some(license => user.user_id === license.data.userID)))  
        }
        if(searchWord === 'users-who-not-made-timestamp-last-30-days') {
            const currentMonth = new Date ().getMonth()
            const currentYear = new Date ().getFullYear()
            const licensesNotUsed = licenses.filter(license=>{
                return new Date (license.ts/1000).getMonth() !== currentMonth && new Date (license.ts/1000).getFullYear() !== currentYear
            })
            setSlicedData(users.filter(user => licensesNotUsed.some(license => user.user_id === license.data.userID)))  
        }
        if(searchWord === 'users-who-didnt-use-license-7-days') {
            const now = new Date ()
            const licensesNotUsed7Days = licenses.filter(license=>{
                return  new Date (license.ts/1000) <= new Date (now.setDate(now.getDate()+7) && new Date (license.ts/1000) >= new Date () )
            })
            setSlicedData(users.filter(user => licensesNotUsed7Days.some(license => user.user_id === license.data.userID)))  
        }
        if(searchWord === 'all-users') {
            setSlicedData(users)
        } 
    }
    return (
        <div className="my-2 flex sm:flex-row flex-col justify-between">
            <div className=" flex">
                <div className="flex flex-row mb-1 sm:mb-0">
                    <div className="relative">
                        <select
                            onChange={(e)=>setSearchWord(e.target.value)}
                            className=" h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500">
                            <option value="all-users">All users</option>
                            <option value="last-10-logins">Last 10 Logins</option>
                            <option value="new-users-in-current-month">New users in current month</option>
                            <option value="users-without-licenses">Users without licenses</option>
                            <option value="users-who-not-made-timestamp-last-30-days">Users who not made timestamp last 30 days</option>
                            <option value="users-who-didnt-use-license-7-days">Users didn&apos;t use license 7 days</option>
                        </select>
                        <div
                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className=" relative">
                    <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                            <path
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                            </path>
                        </svg>
                    </span>
                    <input placeholder="Search"
                        onChange={(e)=>setsearchingword(e)}
                        className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
                </div>
                <button onClick={()=>search()} className="bg-yellow ml-2 px-4 rounded-md">Search</button>
            </div>
           
        </div>
    )

}
export default HeaderTableUsers