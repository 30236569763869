import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
const Profile = () => {
    const {user}= useAuth0()
    return (
        <div>
            <img src={user.picture}/>
            <p>Email: {user.email}</p>
            <p>Name: {user.name}</p>
            <p>Nickname: {user.nickname}</p>
        </div>
    )
}
export default Profile