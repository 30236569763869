/* eslint-disable react/prop-types */
import React from 'react'
const TotalCards = ({users,licenses,totalPoints,totalPointsUsed, expiredLicenses}) => {
    return (
        <div className="grid grid-cols-5 mb-4">
            <div className="flex items-center justify-around p-6 bg-white w-56 rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                    <span className="text-sm font-semibold text-gray-400">Total Users</span>
                    <h1 className="text-2xl font-bold">{users.length}</h1>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                </div>
            </div>
            <div className="flex items-center justify-around p-6 bg-white w-56 rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                    <span className="text-sm font-semibold text-gray-400">Total Licenses</span>
                    <h1 className="text-2xl font-bold">{licenses}</h1>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
                    </svg>
                </div>
            </div>
            <div className="flex items-center justify-around p-6 bg-white w-56 rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                    <span className="text-sm font-semibold text-gray-400">Total active points</span>
                    <h1 className="text-2xl font-bold">{totalPoints}</h1>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                </div>
            </div>
            <div className="flex items-center justify-around p-6 bg-white w-56 rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                    <span className="text-sm font-semibold text-gray-400">Total used points</span>
                    <h1 className="text-2xl font-bold">{totalPointsUsed}</h1>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                </div>
            </div>
            <div className="flex items-center justify-around p-6 bg-white w-56 rounded-xl space-x-2 mt-10 shadow-lg">
                <div>
                    <span className="text-sm font-semibold text-gray-400">Total expired </span>
                    <h1 className="text-2xl font-bold">{expiredLicenses}</h1>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                    </svg>
                </div>
            </div>
        </div>
    )
}
export default TotalCards