/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
const Pages = ({numberLogs,logs,currPage,setCurrPage,getLogs, setAfter,setBefore,data}) => {
    //const maxPage = Math.ceil(logs?.length/numberLogs)
    const next = () =>{
        setCurrPage(currPage+1)
        setAfter(data?.after)
        setBefore(null)
    }
    const previous = () =>{
        if(currPage!==1) {
            setCurrPage(currPage-1)  
            setBefore(data?.before)
            setAfter(null)
        }
            
    }
    useEffect(()=>{
        //setSlicedData(logs.slice((currPage-1)*numberLogs, numberLogs*currPage))
    },[currPage])
    return (
        <div
            className="px-5 py-5 bg-blue text-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm ">
                Page {currPage}
                {/*Showing {currPage===1 ? 1 : (currPage-1)*numberLogs} to {currPage !== maxPage ? currPage*numberLogs : logs.length} of {logs.length} Entries*/}
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
                <button
                    onClick={()=>previous()}
                    className="text-sm bg-white text-blue hover:bg-gray-100  font-semibold py-2 px-4 rounded-l">
                                Prev
                </button>
                <button
                    onClick={()=>next()}
                    className="text-sm bg-white text-blue hover:bg-gray-100 font-semibold py-2 px-4 rounded-r">
                                Next
                </button>
            </div>
        </div>
    )
}
export default Pages