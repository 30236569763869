/* eslint-disable react/prop-types */
import React from 'react'
const MonthTable = ({expiredAvailablePoints,expiredPoints,expiredLicensesMonth}) => {
    return (
        <div className="relative flex flex-col min-w-0 break-words bg-gray-50 dark:bg-gray-800 w-full shadow-lg rounded">
            <div className="rounded-t mb-0 px-0 border-0">
                <div className="flex flex-wrap items-center px-4 py-2">
                    <div className="relative w-full max-w-full flex-grow flex-1">
                        <h3 className="font-semibold text-base text-gray-900 dark:text-gray-50">Full Statistic of expired licenses</h3>
                    </div>
                    <div className="relative w-full max-w-full flex-grow flex-1 text-right">
                        <button className="bg-blue-500 dark:bg-gray-100 text-white active:bg-blue-600 dark:text-gray-800 dark:active:text-gray-700 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">See all</button>
                    </div>
                </div>
                <div className="block w-full">
                    <div className="px-4 bg-gray-100 dark:bg-gray-600 text-gray-500 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Monthly
                    </div>
                    <ul className="my-1">
                        <li className="flex px-4">
                            <div className="w-9 h-9 rounded-full flex-shrink-0 bg-indigo-500 my-2 mr-3">
                                <svg className="w-9 h-9 fill-current text-indigo-50" viewBox="0 0 36 36"><path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z"></path></svg>
                            </div>
                            <div className="flex-grow flex items-center border-b border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                                <div className="flex-grow flex justify-between items-center">
                                    <div className="self-center">
                                               Expired Available Points <span className="font-bold text-lg ml-3">{expiredAvailablePoints}</span>
                                    </div>
                                           
                                </div>
                            </div>
                        </li>
                        <li className="flex px-4">
                            <div className="w-9 h-9 rounded-full flex-shrink-0 bg-indigo-500 my-2 mr-3">
                                <svg className="w-9 h-9 fill-current text-indigo-50" viewBox="0 0 36 36"><path d="M18 10c-4.4 0-8 3.1-8 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L18.9 22H18c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z"></path></svg>
                            </div>
                            <div className="flex-grow flex items-center border-b border-gray-100 dark:border-gray-400 text-sm text-gray-600 dark:text-gray-100 py-2">
                                <div className="flex-grow flex justify-between items-center">
                                    <div className="self-center">
                                               Expired Prices - expired points who are recharged later <span className="font-bold text-lg ml-3">{expiredPoints}</span>
                                    </div>
                                           
                                </div>
                            </div>
                        </li>
                        <li className="flex px-4">
                            <div className="w-9 h-9 rounded-full flex-shrink-0 bg-red-500 my-2 mr-3">
                                <svg className="w-9 h-9 fill-current text-red-50" viewBox="0 0 36 36"><path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z"></path></svg>
                            </div>
                            <div className="flex-grow flex items-center border-gray-100 text-sm text-gray-600 dark:text-gray-50 py-2">
                                <div className="flex-grow flex justify-between items-center">
                                    <div className="self-center">
                          Expired Licenses <span className="font-bold text-lg ml-3">{expiredLicensesMonth}</span>
                                    </div>
                                           
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default MonthTable