/* eslint-disable react/prop-types */
import React, {  } from 'react'
import { VictoryPie } from 'victory'
const Charts = ({chart1,chart2,licenses,totalPoints,totalPointsUsed}) =>{

    return (
        <div>
            <div className="flex items-center justify-center">
                {chart2 && chart2.length>0 ? 
                    <div className="flex flex-col items-center">
                        <h3 className="font-normal text-base items-center text-gray-900 dark:text-gray-50">Points ({(totalPoints+totalPointsUsed) || null})</h3>
                        <VictoryPie
                            text="Popularity of Dog Breeds by Percentage"
                            width={300}
                            height={300}
                            padding={30}
                            colorScale={['#1C365E', '#3b72c4' ]}
                            data={chart2}
                            labelRadius={({ innerRadius }) => innerRadius + 35 }
                            innerRadius={0}
                            labels={({ datum }) => `${datum.y} ${datum.x}`}
                            style={{
                                labels: {
                                    fontSize: 14, fill: 'white'
                                }
                            }}
                            animate={{ easing: 'exp' }}
                        />  
                    </div> :null }
   
                {chart1 && chart1.length>0 ? <div className="flex flex-col  items-center"> 
                    <h3 className="font-normal text-base items-center text-gray-900 dark:text-gray-50">Licenses ({licenses || null})</h3>
                    <VictoryPie
                        width={300}
                        height={300}
                        padding={30}
                        animate={{
                            duration: 4000,
                            easing: 'exp'
                        }}
                        colorScale={['#F9C712', '#FCE31B' ]}
                        data={chart1}
                        labelRadius={({ innerRadius }) => innerRadius + 35 }
                        innerRadius={0}
                        labels={({ datum }) => `${datum.y} ${datum.x}`}
                        style={{
                            labels: {
                                fontSize: 14, fill: 'black'
                            }
                        }}
                    />
                </div> : null}
            </div> 

        </div>
    )
}
export default Charts