/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
export const StoreContext = createContext()

const StoreContextProvider = (props) =>{
    const {getAccessTokenSilently, user, isAuthenticated, isLoading,getIdTokenClaims} = useAuth0()
    const [admin, setAdmin] = useState(false)
    const [users, setUsers] = useState([])
    const [accessTokenFauna, setAccessTokenFauna] = useState()
    const [ licenses, setLicenses] = useState([])
    const [ counters, setCounters] = useState([])
    const [ authToken,setAuthToken] = useState()
 
    //get all users
    const getUsers = (accessToken) => {
        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`

        fetch(userDetailsByIdUrl, {
            method:'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res=>res.json())
            .then(res=>{
                console.log('token data res new',res)
                setUsers(res)
            })
            .catch(err=>console.log('err users',err))

    }

    //get all licenses
    const getLicenses = () =>{
        fetch(`${process.env.REACT_APP_GETALLLICENSES}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log('all licenses',data.data)
                setLicenses(data.data)

            })
            .catch(err=>console.log('err',err))
    }

    //get license by id
    const getLicenseByID = (id) =>{
        fetch(`${process.env.REACT_APP_GETLICENSEBYID}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna,licenseID:id})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log('licenses by id',data.data)

                setLicenses(data.data)

            })
            .catch(err=>console.log('err',err))
    }


    //get license by userID
    const getLicenseByUserID = (userID) =>{
        fetch(`${process.env.REACT_APP_GETLICENSEBYUSERID}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna,userID:userID})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log(data,'licenses by user id',data.data)
                setLicenses(data.data)

            })
            .catch(err=>console.log('err',err))
    }

    //get license by email
    const getLicenseByEmail = (email) =>{
        fetch(`${process.env.REACT_APP_GETLICENSEBYEMAIL}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna,email:email})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log(data,'licenses by email',data.data,'fauna',accessTokenFauna)
                setLicenses(data?.data || [])

            })
            .catch(err=>console.log('err',err))
    }

    //get licenses expired
    const getLicensesExpired = () =>{
        fetch(`${process.env.REACT_APP_GETLICENSESEXPIRED}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log('licenses expired',data.data)
                setLicenses(data.data)

            })
            .catch(err=>console.log('err',err))
    }

    //grab a role of currently logged user
    const getCurrentUser = async (accessToken) =>{
        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}/roles`

        fetch(userDetailsByIdUrl, {
            //method='PATCH'
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then(res=>res.json())
            .then(res=>{
                console.log('token data res',res)
                if(res.length>0 && res[0].name === 'Admin'){ 
                    setAdmin(true) 
                    getUsers(accessToken)
                    getAuth0TokenFull()  
                }  
            })
            .catch(err=>{
                console.log('err profile',err)
            })

    }

    const getAllCounters = () =>{
        fetch(`${process.env.REACT_APP_GETALLCOUNTERS}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log(data,'counters',data,'fauna',accessTokenFauna)
                setCounters(data)

            })
            .catch(err=>console.log('err',err))
    }
    const getAuth0TokenFull = () => {
        // call full token which has access for blocking, deleting user only if  role is admin
        var options = { method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: '{"client_id":"bk71sdGdU33u2D3aqQ6cUzyZRWojOHxd","client_secret":"xIYUz_4baWZ0xNmP7pZnl1qerq7_F8d3BzARveqr6PsHvliy9aTPdRaz2teruPV8","audience":"https://rictatechnologiesdotnet.eu.auth0.com/api/v2/","grant_type":"client_credentials"}' }
        fetch('https://rictatechnologiesdotnet.eu.auth0.com/oauth/token',options)
            .then(res=>res.json())
            .then(res=>{
                console.log('res token full',res)
                setAuthToken(res.access_token)
            })
            .catch(err=>console.log('err token',err))  
    }

    // get a token for checking the users role
    const getInitialData = async (accessTokenFauna) => {
        var options = { method: 'POST',
            url: 'https://rictatechnologiesdotnet.eu.auth0.com/oauth/token',
            headers: { 'content-type': 'application/json' },
            body: '{"client_id":"LASkZjfyAmMlKsqOpTPtrADFfzD0Uslo","client_secret":"S2lSsSoS4IDozlJL57fzSzfyQNOEJuHd_DV9zjSUSbXiux8DhMx62oIuI1nKaXLp","audience":"https://rictatechnologiesdotnet.eu.auth0.com/api/v2/","grant_type":"client_credentials"}' }

        fetch('https://rictatechnologiesdotnet.eu.auth0.com/oauth/token',options)
            .then(res=>res.json())
            .then(res=>{
                console.log('res token',res)
                //setAuthToken(res.access_token)
                getCurrentUser(res.access_token,accessTokenFauna)
              
            })
            .catch(err=>console.log('err token',err))       
    }

    useEffect(()=>{
        if(isAuthenticated && !isLoading) {
            getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`})
                .then(res => {
                    console.log('access token for fauna', res)
                    setAccessTokenFauna(res)
                    getInitialData(res)
               
                })
        }
    },[isAuthenticated])

    return (
        <StoreContext.Provider value={{accessTokenFauna,authToken,admin,users,getUsers,counters,getAllCounters,licenses,getLicenses,getLicensesExpired,getLicenseByID,getLicenseByUserID,getLicenseByEmail}}>
            {props.children}
        </StoreContext.Provider>
    )
}
export default StoreContextProvider


