import React,{useContext, useEffect} from 'react'
import MonthStats from './dashboardComponents/MonthStats'
import TotalStats from './dashboardComponents/TotalStats'
import DailyStats from './dashboardComponents/DailyStats'
import { StoreContext } from './context/context'
const Dashboard = () => {
    const {getAllCounters} = useContext(StoreContext)
    useEffect(()=>{
        getAllCounters()
    },[])
    return (
        <div className="flex flex-col">
            <div className="sm:text-4xl text-6xl font-bold title-font text-blue items-center flex justify-center"><span>Evistamp</span></div>
            <DailyStats/>
            <MonthStats/>
            <TotalStats/>
        </div>
    )
}
export default Dashboard