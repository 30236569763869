/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../context/context'
import MonthCards from './monthComponents/MonthCards'
import MonthTable from './monthComponents/MonthTable'
import Charts from './Charts'
import MonthSales from './monthComponents/MonthSales'
const MonthStats = () =>{ 

    const {users, counters} = useContext(StoreContext)
    const [data,setData] = useState()
    const [chart, setChart] = useState([])
    const [usersMonth, setUsersMonth] = useState([])
    const current = new Date ()
    current.setMonth(current.getMonth()-1)
    useEffect(()=>{
        setData(counters?.monthly)
        console.log('data monthly',counters.monthly)
    },[counters])

    useEffect(()=>{
        setUsersMonth(users.filter(user=> new Date(user.created_at).getMonth() === new Date (current).getMonth() && new Date (user.created_at).getFullYear() === new Date ().getFullYear()
        ))
        if(counters.monthly?.data[0]?.data?.newLicensesAvailablePoints?.data[0] && counters.monthly?.data[0]?.data?.newLicensesUsedPoints?.data[0])
            setChart([
                {x:'available', y: counters.monthly?.data[0]?.data?.newLicensesAvailablePoints?.data[0]},
                {x:'used', y:counters.monthly?.data[0]?.data?.newLicensesUsedPoints?.data[0]}
            ])
    },[counters,data])

    return (
        <div>
            <div className="flex w-full">
                <div className="w-full mb-6 lg:mb-0">
                    <h1 className="sm:text-3xl text-4xl font-medium title-font mb-2 text-blue">Statistic {current.toLocaleString('default', { month: 'long', year:'numeric' })}</h1>
                    <div className="h-1 w-20 bg-indigo-500 rounded"></div>
                </div>
            </div>
            <div className="flex flex-col justify-center">
                <MonthCards usersMonth={usersMonth} newLicensesMonth={counters.monthly?.data[0]?.data?.newLicenses?.data[0]+ counters.monthly?.data[0]?.data?.newTopupLicenses?.data[0]} totalPointsMonth={counters.monthly?.data[0]?.data?.newLicensesAvailablePoints?.data[0]} totalPointsUsedMonth={counters.monthly?.data[0]?.data?.newLicensesUsedPoints?.data[0]} />
                <div className="grid grid-cols-1 lg:grid-cols-3">
                    <MonthTable expiredAvailablePoints={counters.monthly?.data[0]?.data?.expiredAvailablePoints?.data[0]} expiredPoints={counters.monthly?.data[0]?.data?.expiredPoints?.data[0]} expiredLicensesMonth={counters.monthly?.data[0]?.data?.expiredLicenses?.data[0]}/>
                    <Charts chart2={chart} totalPoints={counters.total?.data[0]?.data?.newLicensesAvailablePoints?.data[0]} totalPointsUsed={counters.monthly?.data[0]?.data?.newLicensesUsedPoints?.data[0]}/>
                    <MonthSales monthSalesExpired={counters.monthly?.data[0]?.data?.expiredPrices?.data[0]} monthSales={counters.monthly?.data[0]?.data?.newLicensesPrice?.data[0]} />
                </div>
    
            </div>
 
        </div>

    )
}
export default MonthStats