import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
const NotAuthorized = () => {
    const {logout} = useAuth0() 
    return (
        <div className="h-screen bg-blue text-white flex flex-col items-center justify-center">
            <p className="text-4xl">You are not authorized to enter.</p>
            <button className="bg-yellow text-blue text-2xl px-10 p-2 my-4" onClick={()=>logout()}>Logout</button>
        </div>
    )
}
export default NotAuthorized