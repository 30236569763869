/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React,{ useContext,useState } from 'react'
import HeaderTable from './licensesComponents/headerTable'
import { StoreContext } from './context/context'
import EditModal from './modals/EditModal'
import EditIcon from '@mui/icons-material/Edit'
import CanceledLicenses from './licensesComponents/canceledLicenses'
const Licenses = ()=> {
    const {licenses}= useContext(StoreContext)
    const [ showModal, setShowModal ] = useState(false)
    const [ currentLicense, setCurrentLicense ] = useState()

    return (
        <div className="px-8">
            <div className="bg-blue shadow-md rounded-md">
                <div className="container mx-auto px-4 sm:px-8 text-white">
                    <div className="py-4">
                        <div>
                            <h2 className="text-2xl font-semibold leading-tight">Licenses</h2>
                        </div>
                        <HeaderTable/>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    ID
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Created
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Expire
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 m-auto border-gray-200 bg-blue text-left text-xs text-yellow ">
                                    Last updated
                                            </th>

                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Product
                                            </th>
                                           
                                            <th
                                                className="px-5 py-3 border-b-2 m-auto border-gray-200 bg-blue text-left text-xs text-yellow ">
                                    User ID
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Available
                                            </th>
                                            
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs text-yellow  ">
                                    Used
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Block
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Edit
                                            </th>
                                            
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {licenses.length>0 ? licenses.map((license,i)=>{
                                            return(
                                                <tr key={i} className="text-white">
                                                    <td onClick={()=>setCurrentLicense(license)} className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap cursor-pointer">
                                                            {license.data.id}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200 bg-blue text-sm text-white">
                                                        <div className="flex items-center">
                                                            <div className="ml-3">
                                                                <p className=" whitespace-no-wrap">
                                                                    {new Date(license.data.created).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className={`${new Date()>new Date(license.data.expire) ? 'text-red-700 font-semibold': 'text-white'}  whitespace-no-wrap`}>
                                                            {new Date(license.data.expire).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {new Date(license.ts/1000).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            { license.data.product}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            { license.data.userID}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { license.data.available}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { license.data.used}
                                                        </p>
                                                    </td>  
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { license.data.block ? 'true' : 'false'}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm cursor-pointer" onClick={()=>{setShowModal(true); setCurrentLicense(license)}}>
                                                        <p className=" whitespace-no-wrap">
                                                            <EditIcon/>
                                                        </p>
                                                    </td> 
                                                </tr>
                                            )
                                        }) : <div className="flex justify-center">No licenses found</div>}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CanceledLicenses currentLicense={currentLicense}/>
            {showModal && <EditModal showModal={showModal} setShowModal={setShowModal} currentLicense={currentLicense}/>}
        </div>
    )
}
export default Licenses