/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from 'react'
import { StoreContext } from './context/context'
import HeaderTableLogs from './logs/headerTableLogs'
import Pages from './logs/Pages'
const Logs = () => {
    const [logs, setLogs] = useState([])
    const [data, setData] = useState([])
    const [after, setAfter] = useState(null)
    const [ before, setBefore] = useState(null)
    const [numberLogs, setNumberLogs] = useState(5)
    const [currPage, setCurrPage] = useState(1)
    const {accessTokenFauna} =useContext(StoreContext)
    const getLogs = () =>{
        fetch(`${process.env.REACT_APP_GETALLLOGS}`, {
            method: 'POST',

            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
   
            },
            body: JSON.stringify({ accessToken:accessTokenFauna,size:numberLogs, after:after,before:before})
        })
            .then (res=> res.json())
            .then (data=>{
                console.log('logs',data)
                setData(data)
                setLogs(data.data)
            })
            .catch(err=>console.log('err',err))
    }

    useEffect(()=>{
        getLogs()
    },[])
    useEffect(()=>{
        getLogs()
    },[numberLogs,after,before])
    

    return (
        <div>
            <div className="bg-blue my-8 shadow-md rounded-md">
                <div className="container mx-auto px-4 sm:px-8 text-white">
                    <div className="py-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-tight">Logs</h2>
                        </div>
                        <HeaderTableLogs setNumberLogs={setNumberLogs}/>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    ID
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                   User ID
                                            </th>

                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Date Called
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Country IP
                                            </th>
                                           
                                            <th
                                                className="px-5 py-3 border-b-2 m-auto border-gray-200 bg-blue text-left text-xs text-yellow ">
                                    Soruce IP
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Useragent
                                            </th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logs?.length>0 ? logs.map((logs,i)=>{
                                            return(
                                                <tr key={i} className="text-white">
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap cursor-pointer">
                                                            {logs.data.id}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200 bg-blue text-sm text-white">
                                                        <div className="flex items-center">
                                                            <div className="ml-3">
                                                                <p className=" whitespace-no-wrap">
                                                                    {logs.data.userID}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {new Date(logs.data.created).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            {logs.data.country_ip}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            {logs.data.source_ip}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {logs.data.x_useragent}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        }): 'no logs found'}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Pages logs={logs} getLogs={getLogs} numberLogs={numberLogs} currPage={currPage} setCurrPage={setCurrPage} setBefore={setBefore} data={data} setAfter={setAfter}/>
            </div>

        </div>
    )
}
export default Logs