/* eslint-disable no-unused-vars */
import React, {useState, useContext} from 'react'
import Body from './Body'
import { useAuth0 } from '@auth0/auth0-react'
import { StoreContext } from './context/context'
import NotAuthorized from './notAuthorized'

const Home = ()=> {
    const [ activeCategory,setActiveCategory ] = useState('Dashboard')
    const [openSearch, setOpenSearch] = useState(false)
    const { logout }= useAuth0()
    const {admin}= useContext(StoreContext)

    return (
        <div>
            {admin ? <>
                <div className="flex">
                    <div className="h-screen w-48 px-4 border-r bg-white fixed ">
                        <div className="h-3/4 flex flex-col justify-evenly text-gray-500">
                            <h3 className="pl-1 text-sm flex items-center cursor-pointer py-2 mb-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in"  onClick={()=>setActiveCategory('Dashboard')}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                                <div className="hover:text-black transition duration-200 ease-linear">Dashboard</div>
                            </h3>
                            <h3 className="pl-1 text-sm flex items-center cursor-pointer py-2 mb-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in" onClick={()=>setActiveCategory('Licenses')}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                    <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                                </svg>
                                <div className="hover:text-black transition duration-200 ease-linear">Licenses</div>
                            </h3>
                            <h3 className="pl-1 text-sm flex items-center cursor-pointer py-2 mb-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in" onClick={()=>setActiveCategory('Users')}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                                </svg>
                                <div className="hover:text-black transition duration-200 ease-linear" >Users</div>
                            </h3>
                            <h3 className="pl-1 group text-sm flex flex-col items-left hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in" onClick={()=>setActiveCategory('Logs')}>
                                <div className=" flex items-left justify-start py-2 cursor-pointer" >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                        <path fillRule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clipRule="evenodd" />
                                    </svg>
                                    <div className="hover:text-black transition duration-200 ease-linear" >Logs</div>
                                </div>
                                {/*<div className="pl-1 hidden group-hover:block text-sm text-left mt-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in flex-col" >
                                    <div className="hover:text-black transition duration-200 ease-linear cursor-pointer py-1" onClick={()=>setActiveCategory('SearchByLicense')}>Search by License</div>
                                    <div className="hover:text-black transition duration-200 ease-linear cursor-pointer py-1" onClick={()=>setActiveCategory('SearchByEmail')}>Search by Email</div>
            </div>*/}  
                   
    
                            </h3>
                            <h3 className="pl-1 text-sm flex cursor-pointer items-center py-2 mb-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                    <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                </svg>
                                <div className="hover:text-black transition duration-200 ease-linear" >Settings</div>
                            </h3>
                            <h3 className="pl-1 text-sm flex cursor-pointer items-center py-2 mb-2 hover:bg-gray-100 hover:text-gray-700 transition duration-200 ease-in">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="black">
                                    <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                </svg>
                                <div className="hover:text-black transition duration-200 ease-linear" onClick={()=>logout({returnTo: window.location.origin})} >Logout</div>
                            </h3>
                        </div>
                    </div>
                    <div className="w-full h-full ml-48">
                        <Body activeCategory={activeCategory}/>
                    </div>
                </div>
            </>
                : <NotAuthorized/>}
        </div>
    )
}
export default Home