/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../context/context'
import TotalCards from './totalComponents/TotalCards'
import Charts from './Charts'
import TotalTable from './totalComponents/TotalTable'
import TotalSales from './totalComponents/TotalSales'
const TotalStats = () => {
    const {users, counters} = useContext(StoreContext)
    const [data,setData] = useState()
    const [chart1, setChart1] = useState([])
    const [chart2, setChart2] = useState([])
    useEffect(()=>{
        setData(counters?.total)
        console.log('data',counters.total)
    },[counters])

    useEffect(()=>{
        if(counters.total?.data[0]?.data?.licenses?.data[0] && counters.total?.data[0]?.data?.expiredLicenses?.data[0])
            setChart1([
                {x:'available', y: counters.total?.data[0]?.data?.licenses?.data[0]},
                {x:'expired', y:counters.total?.data[0]?.data?.expiredLicenses?.data[0]}
            ])
        if(counters.total?.data[0]?.data?.licensesAvailablePoints?.data[0] && counters.total?.data[0]?.data?.licensesUsedPoints?.data[0])
            setChart2([
                {x:'active', y: counters.total?.data[0]?.data?.licensesAvailablePoints?.data[0]},
                {x:'used', y:counters.total?.data[0]?.data?.licensesUsedPoints?.data[0]}
            ])
    },[counters,data])
    
    return (
        <div className="mt-6">
            <div className="w-full lg:mb-0 mt-4">
                <h1 className="sm:text-3xl text-4xl font-medium title-font mb-2 text-blue">Statistic Total</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
            <TotalCards users={users} licenses={data?.data[0]?.data?.licenses?.data[0]} totalPoints={data?.data[0]?.data?.licensesAvailablePoints?.data[0]} totalPointsUsed={data?.data[0]?.data?.licensesUsedPoints?.data[0]} expiredLicenses={data?.data[0]?.data?.expiredLicenses?.data[0]} />
            <div className="grid grid-cols-3 mt-4">
                <TotalTable expiredLicenses={data?.data[0]?.data?.expiredLicenses?.data[0]} expiredAvailablePoints={data?.data[0]?.data?.expiredAvailablePoints?.data[0]} expiredPoints={data?.data[0]?.data?.expiredPoints?.data[0]} expiredPrices={data?.data[0]?.data?.expiredPrices?.data[0]} /> 
                <Charts chart1={chart1} chart2={chart2} totalPoints={data?.data[0]?.data?.licensesAvailablePoints?.data[0]+ data?.data[0]?.data?.licensesUsedPoints?.data[0] } totalPointsUsed={data?.data[0]?.data?.licenses?.data[0]} licenses={data?.data[0]?.data?.licenses?.data[0]}/>
                <TotalSales licensesPrices={data?.data[0]?.data?.licensesPrice?.data[0]} licensesExpiredPrices={data?.data[0]?.data?.expiredPrices?.data[0]}/>
            </div>

        </div>
    )
}
export default TotalStats