/* eslint-disable react/prop-types */
import React from 'react'
const CanceledLicenses = ({currentLicense}) => {
    console.log('rrrr',currentLicense)
    return (
        <div className="my-4">
            <div className="font-bold font-lg my-2">Canceled/Refunded Licenses</div>
            <div className="block w-full overflow-x-auto">
                <table className="items-center bg-white border-collapse w-full">
                    <thead>
                        <tr>
                            <th className="px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">License ID</th>
                            <th className="px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">User ID</th>
                            <th className="px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Reason</th>
                            <th className="px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Points Refunded</th>
                            <th className="px-4 bg-gray-300 dark:bg-gray-600 text-gray-700 dark:text-gray-100 align-middle border border-solid border-gray-200 dark:border-gray-500 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">Money Refunded</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentLicense && currentLicense.data.canceled.length>0 ? currentLicense.data.canceled.map((license,i)=>{
                            return (
                                <tr key={i} className="text-gray-700 dark:text-gray-100">
                                    <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{currentLicense.data.id}</td>
                                    <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{currentLicense.data.userID}</td>
                                    <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{license.reason}</td>
                                    <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{license.qtyRefunded}</td>
                                    <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">$ {license.totalRefund}</td>     
                                </tr>
                            )
                        }): null}
                                   
                        
                        
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default CanceledLicenses