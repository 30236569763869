/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext,useState } from 'react'
import Modal from 'react-modal'
import CloseIcon from '@mui/icons-material/Close'
import { StoreContext } from '../context/context'
const BlockModal = ({showModal, setShowModal, currentUser}) => {
    const { authToken,getUsers,getLicenses,accessTokenFauna,licenses } = useContext(StoreContext)
    const [error, setError] = useState(false)
    const customStyles = {
        content : {
            top                   : '50%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)',
            width                 : 'auto',
            height                : 'auto',
            padding               : '0px',
            background            : '#efeff0',
           
        },
        overlay: {
            zIndex: 1000,
            background: '#0000009a',
        }
    }
    const closeModal = () => {
        setShowModal(false)
    }
    const handleUpdate = () => {
        console.log('current user',currentUser, currentUser.blocked)
        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${currentUser.user_id}`
        fetch(userDetailsByIdUrl, {
            method:'PATCH',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({blocked:!currentUser.blocked})
        })
            .then(res=>res.json())
            .then(res=>{
                console.log('token data res',res, !currentUser.blocked)
                if(res && !res.error) {
                    const currentLicense=licenses.filter(license=>license.data.userID === currentUser.user_id)
                    if(currentLicense.length>0) {
                        currentLicense.forEach(curr => {
                            curr.data.block = !currentUser.blocked
                            
                        })
                        console.log('ccc',currentLicense)
                        fetch(`${process.env.REACT_APP_UPDATELICENSES}`, {
                            method:'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            body: JSON.stringify({license:currentLicense, accessToken:accessTokenFauna})
                        })
                            .then(res=>res.json())
                            .then(res=>{
                                console.log('token data res',res)
                                if(res === 'true') {
                                    getLicenses(accessTokenFauna)
                                }
                           
                            })
                            .catch(err=>{
                                console.log('err profile',err)
                            })
                    }
                    else {
                        closeModal()
                        getUsers(authToken)
                    }
                }
                else {
                    setError(res.error)
                }
                
            })
            .catch(err=>{
                console.log('err profile',err)
            })
    }
    return (
        <div>
            <Modal 
                isOpen={showModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="p-4">
                    <div style={{textAlign:'right'}}>
                        <button onClick={() => {closeModal()}}><CloseIcon/> </button>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-2xl my-2">Block user</div>
                        <div className="my-2 px-4">Are you sure you want to <span className="font-bold">{currentUser && currentUser.blocked ? 'UNBLOCK': 'BLOCK'}</span> user <b>{currentUser && currentUser.email} </b>
                         and <b>all</b> according licenses?</div>
                        <div className="flex justify-evenly items-center w-full my-3">
                            <button onClick={()=>handleUpdate()} className="bg-blue text-white px-10 p-2">Yes</button>
                            <button onClick={()=>closeModal()} className="bg-gray-500  text-white px-10 p-2">No</button>
                        </div>
                        {error}
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default BlockModal