/* eslint-disable react/prop-types */
import React from 'react'
import Dashboard from './Dashboard'
import Profile from './Profile'
import Users from './Users'
import Logs from './Logs'
import Licenses from './Licenses'
const Body = ({ activeCategory }) => {
    console.log('acc delila',activeCategory)
    return (
        <div className="bg-gray-100 h-full p-4">
            {activeCategory==='Dashboard' && <Dashboard/>}
            {activeCategory==='Logs' && <Logs/>}
            {activeCategory==='Users' && <Users/>}
            {activeCategory==='Licenses' && <Licenses/>}
            {activeCategory==='Profile' && <Profile/>}
        </div>        
    )
}
export default Body