/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from './context/context'
import HeaderTableUsers from './users/headerTableUsers'
import BlockIcon from '@mui/icons-material/Block'
import BlockModal from './modals/blockModal'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
const Users = () => {
    const {users,getUsers} = useContext(StoreContext)
    const [ slicedData, setSlicedData] =useState(users)
    const [showModal, setShowModal] = useState(false)
    const [currentUser, setCurrentUser] = useState() 
    useEffect(()=>{
        setSlicedData(users)
    },[users])
    return (
        <div>
            <div className="bg-blue my-8 shadow-md rounded-md">
                <div className="container mx-auto px-4 sm:px-8 text-white">
                    <div className="py-8">
                        <div>
                            <h2 className="text-2xl font-semibold leading-tight">Users</h2>
                        </div>
                        <HeaderTableUsers setSlicedData={setSlicedData} users={users}/>
                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th
                                               
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Block
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Email
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Email verifed
                                            </th>

                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue  text-left text-xs   text-yellow">
                                    Acc. created
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Last ip
                                            </th>
                                           
                                            <th
                                                className="px-5 py-3 border-b-2 m-auto border-gray-200 bg-blue text-left text-xs text-yellow ">
                                    Last login
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Logins Count
                                            </th>
                                            
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs text-yellow  ">
                                    Nickname
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs text-yellow  ">
                                    User ID
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Updated at
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Address
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Company
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Country
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Fullname
                                            </th>
                                            
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Job Title
                                            </th>
                                            <th
                                                className="px-5 py-3 border-b-2 border-gray-200 bg-blue text-left text-xs  text-yellow">
                                    Phone Number
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {slicedData.map((user,i)=>{
                                            return(
                                                <tr key={i} className="text-white">
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm"  onClick={()=>{setShowModal(true);setCurrentUser(user)}}>
                                                        <p className=" whitespace-no-wrap cursor-pointer">
                                                            {user.blocked ? <BlockIcon/> : <VerifiedUserIcon/>}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap cursor-pointer">
                                                            {user.email}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            { user.email_verified ? 'true' : 'false'}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200 bg-blue text-sm text-white">
                                                        <div className="flex items-center">
                                                            <div className="ml-3">
                                                                <p className=" whitespace-no-wrap">
                                                                    {new Date(user.created_at).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            { user.last_ip}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className="whitespace-no-wrap">
                                                            {new Date(user.last_login).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { user.logins_count}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { user.nickname}
                                                        </p>
                                                    </td>  
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            { user.user_id}
                                                        </p>
                                                    </td>
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {new Date(user.updated_at).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, ' ')}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.address}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.company}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.country}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.fullname}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.job_title}
                                                        </p>
                                                    </td> 
                                                    <td className="px-2 py-2 border-b border-gray-200  text-sm">
                                                        <p className=" whitespace-no-wrap">
                                                            {user.user_metadata && user.user_metadata.phone_nr}
                                                        </p>
                                                    </td> 
                                                </tr>
                                            )
                                        })}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <BlockModal setShowModal={setShowModal} showModal={showModal} currentUser={currentUser}/> }
        </div>
    )
}
export default Users