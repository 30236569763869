import React, {useEffect} from 'react'
import StoreContextProvider from './components/context/context'
import { useAuth0 } from '@auth0/auth0-react'
import Home from './components/Home'
import Loading from './components/Loading'
function App() {
    const {isAuthenticated,loginWithRedirect, isLoading}= useAuth0()

    console.log('auth',useAuth0())
    useEffect(()=>{
        if(!isAuthenticated && !isLoading) {
            loginWithRedirect()
        }
    },[isLoading,isAuthenticated])
    return (
        <div > 
            <StoreContextProvider>
                {isLoading ? 
                    <Loading/>
                    :
                    <Home/>
                }
            </StoreContextProvider>
        </div>
    )
}

export default App
